import React from 'react';
import {
  Select,
  Card,
  Typography,
  Empty,
  message as showMessage,
  Button,
} from 'antd';
import Spinner from '../common/Spinner';
import { getData } from '../../helper/dataService';
import API from '../../helper/api';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const years = (() => {
  const years = [];
  const startYear = 2019;
  const endYear = new Date().getFullYear();
  let currentYear = startYear;
  while (currentYear <= endYear) {
    years.push(currentYear);
    currentYear += 1;
  }
  return years;
})();

class OnlineReport extends React.Component {
  state = {
    isLoading: true,
    data: [],
    totalBkashPayBillAmount: 0,
    totalBkashPayBillCount: 0,
    totalBkashPGWAmount: 0,
    totalBkashPGWCount: 0,
    totalSslAmount: 0,
    month: months[new Date().getMonth()],
    year: new Date().getFullYear(),
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ isLoading: true });
    const { year, month } = this.state;
    getData(
      `${API.payments.reports}?year=${year}&month=${months.indexOf(month) + 1}`
    )
      .then(
        ({
          data: {
            status,
            message,
            data,
            total_bkash_paybill_amount: totalBkashPayBillAmount,
            total_bkash_paybill_count: totalBkashPayBillCount,
            total_bkash_pgw_amount: totalBkashPGWAmount,
            total_bkash_pgw_count: totalBkashPGWCount,

            total_sslcommerz_amount: totalSslAmount,
            total_sslcommerz_count: totalSslCount,
          },
        }) => {
          if (status === 'success') {
            this.setState({
              isLoading: false,
              data,
              totalBkashPayBillAmount,
              totalBkashPayBillCount,
              totalBkashPGWAmount,
              totalBkashPGWCount,
              totalSslAmount,
              totalSslCount,
            });
          } else {
            this.setState({
              isLoading: false,
            });
            showMessage.error(message);
          }
        }
      )
      .catch(() => {
        this.setState({ data: [], totalAmount: 0, isLoading: false });
        showMessage.error('Sorry! Something went wrong');
      });
  };

  render() {
    const {
      isLoading,
      data,
      month,
      year,
      totalBkashPayBillAmount,
      totalBkashPayBillCount,
      totalBkashPGWAmount,
      totalBkashPGWCount,
      totalSslAmount,
      totalSslCount,
    } = this.state;
    if (isLoading) {
      return <Spinner />;
    }

    return (
      <Card
        title='Online Payment Report'
        extra={
          <React.Fragment>
            <Select
              style={{ width: '120px' }}
              value={year}
              onSelect={(year) => this.setState({ year })}
              size='small'
            >
              {years.map((year) => (
                <Select.Option key={year}>{year}</Select.Option>
              ))}
            </Select>
            <Select
              style={{ width: '120px', marginLeft: '10px' }}
              value={month}
              onSelect={(month) => this.setState({ month })}
              size='small'
            >
              {months.map((month) => (
                <Select.Option key={month}>{month}</Select.Option>
              ))}
            </Select>
            <Button
              style={{ marginLeft: '10px' }}
              size='small'
              onClick={this.fetchData}
            >
              SUBMIT
            </Button>
          </React.Fragment>
        }
      >
        {data.length ? (
          <>
            <Typography.Text>
              <p style={{ textAlign: 'right' }}>
                BKASH(PGW) [{totalBkashPGWCount}]: ৳{totalBkashPGWAmount}
                <br />
                BKASH(Paybill) [{totalBkashPayBillCount}]: ৳
                {totalBkashPayBillAmount}
                <br />
                SSLCOMMERZ [{totalSslCount}]: ৳{totalSslAmount}
                <br />
                ....................................................
                <br />
                Total [
                {totalBkashPayBillCount + totalBkashPGWCount + totalSslCount}]:
                ৳
                {totalBkashPayBillAmount + totalBkashPGWAmount + totalSslAmount}
              </p>
            </Typography.Text>
            <ResponsiveContainer height={300}>
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='date' />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar
                  dataKey='bkash_pgw.count'
                  name='BKASH(PGW)'
                  fill='#913175'
                />
                <Bar
                  dataKey='bkash_paybill.count'
                  name='BKASH(Paybill)'
                  fill='#e3106e'
                />
                <Bar
                  dataKey='sslcommerz.count'
                  name='SSLCOMMERZ'
                  fill='#295cab'
                />
              </BarChart>
            </ResponsiveContainer>
          </>
        ) : (
          <Empty />
        )}
      </Card>
    );
  }
}

const CustomTooltip = ({ label, payload }) => {
  if (payload && payload.length) {
    const { bkash_paybill, bkash_pgw, sslcommerz } = payload[0].payload;

    return (
      <Card size='small'>
        <Typography.Title level={4}>{label}</Typography.Title>
        <Typography.Text strong>BKASH(PGW)</Typography.Text>
        <br />
        <Typography.Text>Count: {bkash_pgw.count}</Typography.Text>
        <br />
        <Typography.Text>Amount: ৳{bkash_pgw.amount}</Typography.Text>
        <br />
        <Typography.Text strong>BKASH(Paybill)</Typography.Text>
        <br />
        <Typography.Text>Count: {bkash_paybill.count}</Typography.Text>
        <br />
        <Typography.Text>Amount: ৳{bkash_paybill.amount}</Typography.Text>
        <br />

        <Typography.Text strong>SSLCOMMERZ</Typography.Text>
        <br />
        <Typography.Text>Count: {sslcommerz.count}</Typography.Text>
        <br />
        <Typography.Text>Amount: ৳{sslcommerz.amount}</Typography.Text>
      </Card>
    );
  }
  return null;
};

export default OnlineReport;
