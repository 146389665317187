import React from 'react';
import {
  Card,
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  Icon,
  Tooltip,
  message as showMessage,
  DatePicker,
  Switch,
} from 'antd';

import { postData, getData } from '../../helper/dataService';
import API from '../../helper/api';
import Spinner from '../common/Spinner';
import {
  delayApiRequest,
  mobileRegexFromPrefix,
  nameFromId,
} from '../../helper/utility';
import moment from 'moment';

const ASSET_TYPES = ['Car', 'Mircrobus', 'Bus', 'Minibus', 'PIckup'];
const PLATFORMS = ['Finder Plus', 'Telematics', 'Tracksolid Pro'];
const SERVICE_PLANS = [350, 400, 600, 900, 1000];
const SERVICE_TYPES = ['Tracking', 'Fuel', 'Camera'];

class AssetAdd extends React.Component {
  state = {
    orgData: [],
    orgFetching: false,
    deviceData: [],
    deviceFetching: false,
    submitted: false,
  };

  //* Device
  handleFetchDevice = (deviceName) => {
    if (deviceName.length >= 4) {
      delayApiRequest(() => this.fetchDevice(deviceName));
    }
  };

  fetchDevice = (deviceName) => {
    this.props.form.resetFields(['device_oid']);

    this.setState({ deviceData: [], deviceFetching: true });
    getData(API.device.search(deviceName))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ deviceData: data });
        } else {
          showMessage.error('Sorry! Something went wrong');
        }
        this.setState({ deviceFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ deviceFetching: false });
      });
  };

  clearDevice = () => {
    this.setState({ deviceData: [] });
    this.props.form.resetFields(['device_oid']);
  };

  // Organization
  handleFetchOrg = (organizationName) => {
    if (organizationName.length >= 4) {
      delayApiRequest(() => this.fetchOrg(organizationName));
    }
  };

  fetchOrg = (organizationName) => {
    // Clear current selected organization oid for FORM state
    this.props.form.setFieldsValue({
      organization_oid: '',
    });

    // Clear current organization and assets list from DOM and replace with loader.
    this.setState({ orgData: [], orgFetching: true });

    // Fetch organization names by searching
    getData(API.organization.search(organizationName))
      .then(({ data: { data, status } }) => {
        if (status === 'success') {
          this.setState({ orgData: data });
        }
        this.setState({ orgFetching: false });
      })
      .catch(() => {
        showMessage.error('Sorry! Something went wrong');
        this.setState({ orgFetching: false });
      });
  };

  clearOrg = () => {
    // Remove selected organization from DOM and FORM state
    this.setState({ orgData: [] });
    this.props.form.setFieldsValue({ organization_oid: '' });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitted: true });

        const data = {
          ...values,
          installation_date: moment(values.installation_date).format(
            'YYYY-MM-DD'
          ),
        };

        postData(API.asset.add, data)
          .then(({ data: { status, display } }) => {
            if (status === 'success') {
              showMessage.success(display);
              this.props.form.resetFields();
              this.setState({
                deviceData: [],
                orgData: [],
              });
            } else if (status === 'error') {
              showMessage.error(display);
            }
            this.setState({ submitted: false });
          })
          .catch(() => {
            showMessage.error('Sorry! Something went wrong');
            this.setState({ submitted: false });
          });
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { deviceFetching, deviceData, orgData, orgFetching, submitted } =
      this.state;

    const currentSelectedDevice = deviceData.find(
      ({ _id }) => _id.$oid === getFieldValue('device_oid')
    );
    const organizationsOid = getFieldValue('organization_oid');

    return (
      <Row type='flex' justify='space-around' align='middle'>
        <Col xs={24} sm={18}>
          <Card
            title={
              <span className='title'>
                <Icon type='car' />
                {' Add Asset'}
              </span>
            }
          >
            <Form onSubmit={this.handleSubmit} layout='vertical'>
              <Form.Item
                label='Organization'
                extra={
                  organizationsOid ? (
                    <Button.Group size='small'>
                      <Button
                        onClick={() => this.showDetails(organizationsOid)}
                      >
                        {nameFromId(organizationsOid, orgData)}
                      </Button>
                      <Tooltip title='Remove organization'>
                        <Button icon='delete' onClick={this.clearOrg} />
                      </Tooltip>
                    </Button.Group>
                  ) : null
                }
                {...formItemLayout}
              >
                {getFieldDecorator('organization_oid', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a organization',
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder='Search and Select Organization'
                    filterOption={false}
                    notFoundContent={
                      orgFetching ? <Spinner /> : 'No Content Found'
                    }
                    onSearch={this.handleFetchOrg}
                    defaultActiveFirstOption={false}
                  >
                    {orgData.length > 0 &&
                      orgData.map((item) => (
                        <Select.Option key={item._id.$oid}>
                          {item.name} [{item.organization_id}]
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label='Asset Name' {...formItemLayout}>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input asset name',
                    },
                  ],
                })(<Input placeholder='Asset Name' />)}
              </Form.Item>

              <Form.Item label='Asset Type' {...formItemLayout}>
                {getFieldDecorator('asset_type', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a asset type',
                    },
                  ],
                })(
                  <Select
                    placeholder='Select asset type'
                    notFoundContent={
                      ASSET_TYPES.length <= 0 ? <Spinner /> : null
                    }
                    showSearch
                  >
                    {ASSET_TYPES.map((item) => (
                      <Select.Option key={item}>{item}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label='Service Type' {...formItemLayout}>
                {getFieldDecorator('service_type', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a service type',
                    },
                  ],
                })(
                  <Select
                    placeholder='Select service type'
                    notFoundContent={
                      SERVICE_TYPES.length <= 0 ? <Spinner /> : null
                    }
                    showSearch
                  >
                    {SERVICE_TYPES.map((item) => (
                      <Select.Option key={item}>{item}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label='Service Plan' {...formItemLayout}>
                {getFieldDecorator('service_plan', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a service plan',
                    },
                  ],
                })(
                  <Select
                    placeholder='Select service plan'
                    notFoundContent={
                      SERVICE_PLANS.length <= 0 ? <Spinner /> : null
                    }
                    showSearch
                  >
                    {SERVICE_PLANS.map((item) => (
                      <Select.Option key={item}>{item}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item
                label='Device'
                extra={
                  currentSelectedDevice ? (
                    <Button.Group size='small'>
                      <Button>
                        {currentSelectedDevice.imei} [
                        {currentSelectedDevice.device_id}] [
                        {currentSelectedDevice.device_type}]
                      </Button>
                      <Tooltip title='Remove device'>
                        <Button icon='delete' onClick={this.clearDevice} />
                      </Tooltip>
                    </Button.Group>
                  ) : null
                }
                {...formItemLayout}
              >
                {getFieldDecorator('device_oid', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a device',
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder='Search and Select Device'
                    filterOption={false}
                    notFoundContent={
                      deviceFetching ? <Spinner /> : 'No Content Found'
                    }
                    onSearch={this.handleFetchDevice}
                    defaultActiveFirstOption={false}
                  >
                    {deviceData.length > 0 &&
                      deviceData.map(
                        ({ _id, imei, device_id, device_type }) => (
                          <Select.Option key={_id.$oid}>
                            {imei} [{device_id}] [{device_type}]
                          </Select.Option>
                        )
                      )}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label='Device Sim Number' {...formItemLayout}>
                {getFieldDecorator('device_sim_number', {
                  rules: [
                    {
                      pattern: mobileRegexFromPrefix('+880'),
                      message: 'The input is not a valid device sim number',
                    },
                    {
                      required: true,
                      message: 'Please input device sim number',
                    },
                  ],
                })(
                  <Input addonBefore={'+880'} placeholder='Device sim number' />
                )}
              </Form.Item>

              <Form.Item label='Installation Date' {...formItemLayout}>
                {getFieldDecorator('installation_date', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please select a installation date',
                    },
                  ],
                })(
                  <DatePicker
                    placeholder='Select installation date'
                    format='DD-MM-YYYY'
                    showToday={false}
                    disabledTime
                    disabledDate={(current) => current > moment().endOf('day')}
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>

              <Form.Item label='Installation Location' {...formItemLayout}>
                {getFieldDecorator('installation_location', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input installation location',
                    },
                  ],
                })(<Input placeholder='Installation location' />)}
              </Form.Item>

              <Form.Item label='Platform' {...formItemLayout}>
                {getFieldDecorator('platform', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a platform',
                    },
                  ],
                })(
                  <Select
                    placeholder='Select platform'
                    notFoundContent={PLATFORMS.length <= 0 ? <Spinner /> : null}
                    showSearch
                  >
                    {PLATFORMS.map((item) => (
                      <Select.Option key={item}>{item}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item label='Remarks' {...formItemLayout}>
                {getFieldDecorator('remarks', {
                  initialValue: '',
                  rules: [
                    {
                      max: 250,
                      message: 'The input not more than 250 characters.',
                    },
                  ],
                })(<Input.TextArea rows={4} placeholder='Remarks' />)}
              </Form.Item>

              <Form.Item label='Active' {...formItemLayout}>
                {getFieldDecorator('active', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(<Switch />)}
              </Form.Item>

              <Button
                type='primary'
                size='large'
                htmlType='submit'
                style={{ float: 'right' }}
                loading={submitted}
              >
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(AssetAdd);
