import React from 'react';
import {
  Card,
  Form,
  Button,
  Input,
  Select,
  Divider,
  Upload,
  Icon,
  message as showMessage,
  Row,
  Col,
  Alert
} from 'antd';
import { getData, uploadFile, postData } from '../../helper/dataService';
import API from '../../helper/api';
import Spinner from '../common/Spinner';

class DeviceAdd extends React.Component {
  state = {
    assetTypes: [],
    file: '',
    submitted: false,
    uploading: false,
    uploadMessage: '',
    uploadSummary: ''
  };

  fetchDeviceTypes = () => {
    const { assetTypes } = this.state;

    if (Array.isArray(assetTypes) && assetTypes.length === 0) {
      this.setState({ assetTypes: 'loading' });
      getData(API.device.type)
        .then(({ data: { data, status, message } }) => {
          if (status === 'success') {
            this.setState({ assetTypes: data });
          } else {
            showMessage.error(message);
          }
        })
        .catch(() => showMessage.error('Sorry! Something went wrong'));
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitted: true });
        postData(API.device.add, values)
          .then(({ data: { status, display } }) => {
            if (status === 'success') {
              this.props.form.resetFields(['imei', 'model', 'remarks']);
              showMessage.success(display);
            } else if (status === 'failed') {
              showMessage.error(display);
            }
            this.setState({ submitted: false });
          })
          .catch(() => {
            showMessage.error('Sorry! Something went wrong');
            this.setState({ submitted: false });
          });
      }
    });
  };

  // ....FILE UPLOAD....

  beforeUpload = file => {
    this.setState({ uploadSummary: '' });
    const fileSize = file.size / 1024 / 1024;
    if (fileSize < 1) {
      this.setState({ file });
    } else {
      showMessage.error(
        `File size limit is 1MB but uploaded ${fileSize.toFixed(2)} MB.`
      );
    }
    return false;
  };

  handleUpload = () => {
    const { file } = this.state;
    const formData = new FormData();
    formData.append('document', file);
    this.setState({
      uploading: true
    });

    uploadFile(API.device.excel, formData)
      .then(({ data: { status, display, data } }) => {
        if (status === 'success') {
          showMessage.success(display);
          const { summary } = data;
          this.setState({
            file: '',
            uploading: false,
            uploadMessage: '',
            uploadSummary: summary
          });
        } else if (status === 'failed') {
          this.setState({ file: '', uploading: false, uploadMessage: display });
        }
      })
      .catch(() => {
        this.setState({ uploading: false, uploadMessage: '' });
        showMessage.error('Sorry! Something went wrong');
      });
  };

  render() {
    const {
      assetTypes,
      submitted,
      file,
      uploading,
      uploadMessage,
      uploadSummary
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    return (
      <Row type='flex' justify='space-around' align='middle'>
        <Col xs={24} sm={18}>
          <Card
            title={
              <span className='title'>
                <Icon type='cluster' />
                {' Add Device'}
              </span>
            }
          >
            <Card bordered={false}>
              <Form onSubmit={this.handleSubmit} layout='vertical'>
                <Form.Item {...formItemLayout} label='IMEI'>
                  {getFieldDecorator('imei', {
                    rules: [
                      {
                        pattern: /^35\d{13}$/,
                        message: 'The input is not a valid IMEI number'
                      },
                      {
                        required: true,
                        message: 'Please input valid IMEI'
                      }
                    ]
                  })(<Input placeholder='IMEI number' />)}
                </Form.Item>

                <Form.Item {...formItemLayout} label='Model'>
                  {getFieldDecorator('model', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select device model'
                      }
                    ]
                  })(
                    <Select
                      placeholder='Select device model'
                      onFocus={this.fetchDeviceTypes}
                      notFoundContent={
                        assetTypes === 'loading' ? <Spinner /> : null
                      }
                    >
                      {Array.isArray(assetTypes) &&
                        assetTypes.map(
                          ({ device_model, device_name, display }, index) => (
                            <Select.Option
                              key={device_model + index}
                              value={device_model}
                            >
                              {`${device_model} [${device_name}] [${display}]`}
                            </Select.Option>
                          )
                        )}
                    </Select>
                  )}
                </Form.Item>

                <Form.Item label='Remarks' {...formItemLayout}>
                  {getFieldDecorator('remarks', {
                    rules: [
                      {
                        max: 250,
                        message: 'The input not more than 250 characters.'
                      }
                    ]
                  })(<Input.TextArea rows={4} placeholder='Remarks' />)}
                </Form.Item>

                <br />
                <Button
                  type='primary'
                  size='large'
                  htmlType='submit'
                  style={{ float: 'right' }}
                  loading={submitted}
                >
                  SUBMIT
                </Button>
              </Form>
            </Card>
            <Divider>OR</Divider>
            <div>
              <Upload
                type='file'
                accept='.xlsx,.xls'
                multiple={false}
                showUploadList={false}
                beforeUpload={this.beforeUpload}
              >
                <Button>
                  <Icon type='upload' />
                  Click to upload
                </Button>
              </Upload>
              <Divider type='vertical' />
              <Button
                type='primary'
                onClick={this.handleUpload}
                disabled={file.length === 0}
                loading={uploading}
                style={{ marginTop: 16 }}
              >
                {uploading ? 'Uploading' : 'Upload'}
              </Button>
              {file.length !== 0 && (
                <span>
                  <Divider type='vertical' />
                  {file.name}
                </span>
              )}
            </div>

            <br />
            {uploadMessage && <Alert message={uploadMessage} type='warning' />}
            {uploadSummary && (
              <Alert
                type='info'
                message='Summary'
                closable
                showIcon
                description={
                  <ul>
                    {Object.keys(uploadSummary).map(item => (
                      <li>{`${item} : ${uploadSummary[item]}`}</li>
                    ))}
                  </ul>
                }
              />
            )}
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Form.create()(DeviceAdd);
